body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
  min-height: 100vh;
  height: 100vh;
  background-color: #c4dfd6;
}

/* Login */



/* RoomCard */

#room_card .card {
  cursor: pointer;
  text-align: center;
}

#room_card .card-body {
  font-family: "Bebas Neue", cursive;
}

#room_card .card-subtitle {
  font-size: 30px;
}

#room_card .card:hover {
  background-color: #87cefa;
}

/* TableList */
#centered {
  text-align: center;
  margin-bottom: 10px;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
}

#centered p {
  margin-bottom: 0;
}

#centered input {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 10px;
  border-radius: 5px;
}

#tables {
  padding: 10px;
  background-color: #87cefa;
  box-shadow: 2px 2px 2px 2px grey;
  border-radius: 10px;
}

/* TableCard */

.tableCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}

/* ALL */